<template lang="pug">
.top-header-contacts-line.lazy(data-lazy-function="loaded")
  .container.is-flex
    .close-button.is-flex(@click="$emit('close')")
      icon(iconName="close", iconColor="#2e2e2e")
    .contacts-block.is-flex(v-for="block in blocks", :key="block.id", :class="block.class")
      .icon-wrapper.is-flex
        icon(:iconName="block.icon", :width="block.iconWidth", :height="block.iconHeight")
      .contacts-block-title {{ block.name }}
      component.contacts-block-content.is-flex(:is="block.component")
</template>

<script>
export default {
  name: 'TopHeaderContactsLineComponent',

  components: {
    emailView: () => import('./TopHeaderContactsLine/emailView'),
    liveChatView: () => import('./TopHeaderContactsLine/liveChatView'),
    locationsView: () => import('./TopHeaderContactsLine/locationsView'),
    phoneView: () => import('./TopHeaderContactsLine/phoneView'),
    showroomView: () => import('./TopHeaderContactsLine/showroomView')
  },

  computed: {
    blocks() {
      return [
        {
          id: '1dc16dd4-d540-40d2-a145-e8b6da447bbe',
          name: 'Showroom',
          class: 'showroom',
          icon: 'showroom',
          iconWidth: 35,
          iconHeight: 25,
          component: 'showroomView'
        },
        {
          id: '91867199-a4a4-4e67-96d6-b1cb00feece3',
          name: 'Locations',
          class: 'locations',
          icon: 'locations',
          iconWidth: 25,
          iconHeight: 35,
          component: 'locationsView'
        },
        {
          id: 'bf372981-029c-4887-83f2-659f39dca13d',
          name: 'Phone',
          class: 'phone',
          icon: 'phone',
          iconWidth: 29,
          iconHeight: 29,
          component: 'phoneView'
        },
        {
          id: 'b2cfb0b7-41e0-4d59-b058-de4db528a050',
          name: 'Live Chat',
          class: 'live-chat',
          icon: 'liveChat',
          iconWidth: 31,
          iconHeight: 26,
          component: 'liveChatView'
        },
        {
          id: '8436803e-9ded-4f34-b8cc-165e16b8499a',
          name: 'Email',
          class: 'email',
          icon: 'email',
          iconWidth: 29,
          iconHeight: 21,
          component: 'emailView'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
//@include background-image('top-header-contacts-line', 'ourStory/bg', 'jpg')
.top-header-contacts-line
  padding: 55px 0 20px
  background-size: 2%
  .close-button
    cursor: pointer
    position: absolute
    top: -37px
    right: 24px
    align-items: center
    text-transform: uppercase
    svg
      margin-left: 5px
      height: 15px
      width: 15px
  .contacts-block
    flex-direction: column
    width: calc(100%/5)
    text-align: center
    background-color: #fff
    padding: 20px 10px
    &.locations
      padding: 20px 50px
    &-title
      font-family: $lora
      font-style: italic
      font-size: 24px
      margin-top: 10px
      line-height: 20px
    .icon-wrapper
      justify-content: center
      align-items: center
      width: 60px
      height: 60px
      border: 1px solid $dark-gray
      border-radius: 50%
      margin: 0 auto
    &-content
      margin-top: 25px
      flex-direction: column
      justify-content: space-between
      flex: auto
    ::v-deep
      .locations-view
        justify-content: initial
      .phone-view
        +until-widescreen
          justify-content: initial
</style>
